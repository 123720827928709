import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { HOME_PATH, REDIRECT_PATH } from '../constants/navigation.constants';
import Suspense from '../components/Suspense';

const Home = lazy(() => import('./Home'));
const Layout = lazy(() => import('./Layout'));
const Redirect = lazy(() => import('./Redirect'));

function Router() {
	return (
		<Routes>
			<Route
				path={HOME_PATH}
				element={
					<Suspense>
						<Layout />
					</Suspense>
				}
			>
				<Route
					index
					element={
						<Suspense>
							<Home />
						</Suspense>
					}
				/>
			</Route>
			<Route
				path={REDIRECT_PATH}
				element={
					<Suspense>
						<Redirect />
					</Suspense>
				}
			/>
		</Routes>
	);
}

export default Router;
