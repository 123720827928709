import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import LoadingContext from '../contexts/loading.context';
import Loader from '../components/Loader';

function LoaderProvider({ children }) {
	const [loading, setLoading] = useState(false);

	const value = useMemo(
		() => ({
			loading,
			setLoading,
		}),
		[loading],
	);

	return (
		<>
			{loading && <Loader />}
			<LoadingContext.Provider value={value}>
				{children}
			</LoadingContext.Provider>
		</>
	);
}

LoaderProvider.propTypes = { children: PropTypes.node.isRequired };

export default LoaderProvider;
