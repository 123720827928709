import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader';

export default function Suspense({ children, fallback }) {
	return <React.Suspense fallback={fallback}>{children}</React.Suspense>;
}

Suspense.defaultProps = {
	children: null,
	fallback: <Loader />,
};

Suspense.propTypes = {
	children: PropTypes.element,
	fallback: PropTypes.element,
};
