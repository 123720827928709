import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import StorageProvider from './providers/storage.provider';
import Router from './pages/Router';
import LoaderProvider from './providers/loader.provider';

function App() {
	return (
		<StorageProvider>
			<LoaderProvider>
				<BrowserRouter>
					<Router />
				</BrowserRouter>
			</LoaderProvider>
		</StorageProvider>
	);
}

export default App;
