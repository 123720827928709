import React from 'react';

export default React.createContext({
	step: 1,
	setStep: () => {},
	id: '',
	setId: () => {},
	connect: true,
	hasBusinessDate: false,
	setHasBusinessDate: () => {},
	setConnect: () => {},
	driverLicense: undefined,
	setDriverLicense: () => {},
	bankInfo: null,
	setBankInfo: () => {},
	voidCheck: undefined,
	setVoidCheck: () => {},
	bankStatements: [],
	setBankStatements: () => {},
	bankStatementsInfo: {},
	setBankStatementsInfo: () => {},
	signPad: {},
	setSignPad: () => {},
	sign: '',
	setSign: () => {},
	errors: {},
	setErrors: () => {},
	formData: {
		businessName: '',
		dba: '',
		typeOfBusiness: '',
		businessActivity: '',
		typeProduct: '',
		purposeFunds: '',
		businessOwnership: '',
		businessDate: null,
		businessAddress: {
			address: '',
			address2: '',
			city: '',
			state: '',
			zipCode: '',
		},
		workPhone: '',
		firstName: '',
		lastName: '',
		phone: '',
		email: '',
		birthday: null,
		ssn: '',
		fein: '',
		address: {
			address: '',
			address2: '',
			city: '',
			state: '',
			zipCode: '',
		},
	},
	setFormData: () => {},
	linkToken: '',
	setLinkToken: () => {},
	isMobile: false,
});
