import React from 'react';

function Loader() {
	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				zIndex: 1000,
				width: '100%',
				height: '100vh',
				background: '#fffc',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<div className="lds-spinner">
				<div />
				<div />
				<div />
				<div />
				<div />
				<div />
				<div />
				<div />
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	);
}

export default Loader;
