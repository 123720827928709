import axios from 'axios';
import PropTypes from 'prop-types';

import { BASE_URL } from '../constants/api.constants';

function AxiosProvider({ children }) {
	axios.defaults.baseURL = BASE_URL;

	axios.interceptors.response.use(({ data }) => data);

	return children;
}

AxiosProvider.propTypes = {
	children: PropTypes.element.isRequired,
};

export default AxiosProvider;
