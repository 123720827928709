import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/styles/index.scss';
import AxiosProvider from './providers/axios.provider';
import WebsocketProvider from './providers/websocket.provider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<AxiosProvider>
		<WebsocketProvider>
			<App />
		</WebsocketProvider>
	</AxiosProvider>,
);
