import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import StorageContext from '../contexts/storage.context';
import { isMobile } from '../utils/common.utils';
import useQueryParams from '../useQueryParams';
import {
	formDataLocalStorage,
	idLocalStorage,
	stepLocalStorage,
} from '../constants/localStorage.constants';

function StorageProvider({ children }) {
	const [step, setStep] = useState(
		[1, 2].includes(Number(localStorage.getItem(stepLocalStorage)))
			? Number(localStorage.getItem(stepLocalStorage))
			: 1,
	);

	const [id, setId] = useState(
		useQueryParams()?.id || localStorage.getItem(idLocalStorage),
	);
	const [connect, setConnect] = useState(true);
	const [driverLicense, setDriverLicense] = useState(undefined);
	const [bankInfo, setBankInfo] = useState(null);
	const [hasBusinessDate, setHasBusinessDate] = useState(false);
	const [bankStatementsInfo, setBankStatementsInfo] = useState({});
	const [voidCheck, setVoidCheck] = useState(undefined);
	const [bankStatements, setBankStatements] = useState([]);
	const [signPad, setSignPad] = useState(undefined);
	const [sign, setSign] = useState(undefined);
	const [errors, setErrors] = useState({});
	const [formData, setFormData] = useState(
		localStorage.getItem(formDataLocalStorage)
			? JSON.parse(localStorage.getItem(formDataLocalStorage))
			: {
					businessName: '',
					dba: '',
					typeOfBusiness: '',
					businessActivity: '',
					businessOwnership: '',
					typeProduct: '',
					purposeFunds: '',
					businessDate: null,
					businessAddress: {
						address: '',
						address2: '',
						city: '',
						state: '',
						zipCode: '',
					},
					workPhone: '',
					firstName: '',
					lastName: '',
					phone: '',
					email: '',
					birthday: null,
					ssn: '',
					fein: '',
					address: {
						address: '',
						address2: '',
						city: '',
						state: '',
						zipCode: '',
					},
			  },
	);

	const [linkToken, setLinkToken] = useState();

	const storeItem = (key, value) => {
		localStorage.setItem(key, JSON.stringify(value));
		return value;
	};

	useEffect(() => {
		if (id) localStorage.setItem(idLocalStorage, id);
	}, [id]);

	useEffect(() => {
		if (formData)
			localStorage.setItem(formDataLocalStorage, JSON.stringify(formData));
	}, [formData]);

	const value = useMemo(
		() => ({
			step,
			setStep: (data) => {
				setStep(data);
				storeItem(stepLocalStorage, data);
			},
			id,
			setId,
			connect,
			setConnect,
			driverLicense,
			setDriverLicense,
			bankInfo,
			setBankInfo,
			voidCheck,
			setVoidCheck,
			bankStatements,
			setBankStatements,
			signPad,
			setSignPad,
			hasBusinessDate,
			setHasBusinessDate,
			bankStatementsInfo,
			setBankStatementsInfo,
			sign,
			setSign,
			errors,
			setErrors,
			formData,
			setFormData,
			linkToken,
			setLinkToken,
			isMobile: isMobile(),
		}),
		[
			step,
			id,
			connect,
			driverLicense,
			bankStatementsInfo,
			bankInfo,
			voidCheck,
			hasBusinessDate,
			bankStatements,
			signPad,
			sign,
			errors,
			formData,
			linkToken,
		],
	);
	return (
		<StorageContext.Provider value={value}>{children}</StorageContext.Provider>
	);
}

StorageProvider.propTypes = {
	children: PropTypes.element.isRequired,
};

export default StorageProvider;
